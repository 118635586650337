@import url('https://fonts.googleapis.com/css2?family=M+PLUS+1:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&family=M+PLUS+1p&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Paytone+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Days+One&family=Paytone+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alike&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Alfa+Slab+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bakbak+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bakbak+One&family=Noto+Color+Emoji&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Monda:wght@400;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;